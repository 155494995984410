Handlebars.registerHelper('formatCurrency', function (value) {
    return formatCurrency(value);
});

window.formatCurrency = function (value) {
    // Stored in variables here so it is easy to swap out later once we have localization
    decPlaces = 2;
    decSeparator = '.';
    thouSeparator = ',';
    currencySymbol = '$';

    if (typeof value == 'string')
        value = parseInt(value);

    var n = value,
        sign = n < 0 ? "-" : "",
        i = parseInt(n = Math.abs(+n || 0).toFixed(decPlaces)) + "",
        j = (j = i.length) > 3 ? j % 3 : 0;

    return sign + currencySymbol + (j ? i.substr(0, j) + thouSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thouSeparator) + (decPlaces ? decSeparator + Math.abs(n - i).toFixed(decPlaces).slice(2) : "");
};