rter = function () {
    var settings = {
        useRemoteTemplates: true,
        templatePath: 'views/'
    };

    $.fn.closestRterView = function () {
        return this.closest('[data-role=rter-view]');
    };

    return {
        mapController: function(namespace, logic) {
            var c = window.controller = window.controller || {};
            var tokens = namespace.split('.');
            for (var i = 0; i < tokens.length; i++) {
                var p = tokens[i];

                if (p == 'controller') continue;

                c = c[p] = c[p] || ((i == tokens.length - 1) ? logic : {});
            }

            c = logic;
        },
        utils: {
            toParamString: function (object) {
                if (typeof object == 'undefined' || object == null) return '';

                var str = '';
                var recurseFunc = function (prefix, o) {
                    if (o instanceof Array) {
                        for (var i = 0; i < o.length; i++) {
                            recurseFunc(prefix + '[' + i + ']', o[i]);
                        }
                    } else if (typeof o == 'object') {
                        for (var p in o) {
                            var newPrefix = (prefix == '') ? p : prefix + '.' + p;
                            recurseFunc(newPrefix, o[p]);
                        }
                    } else {
                        if (str != '')
                            str += '&';

                        str += encodeURIComponent(prefix) + '=' + encodeURIComponent(o);
                        str = str.replace("%5B", '[').replace("%5D", ']');
                    }
                };

                recurseFunc('', object);
                return str;
            },
            parseParamString: function (string) {
                if (typeof string !== 'string') return null;

                var data = {};
                var params = string.split('&');

                for (var i = 0; i < params.length; i++) {
                    var p = params[i];
                    var eqi = p.indexOf('=');
                    var prefix = decodeURIComponent(p.substr(0, eqi));
                    var value = decodeURIComponent(p.substr(eqi + 1));

                    if (isNaN(value) == false)
                        value = parseFloat(value);

                    var node = data;
                    var map = prefix.split('.');
                    for (var x = 0; x < map.length - 1; x++) {
                        var m = map[x];
                        if (typeof node[m] === 'undefined')
                            node[m] = {};

                        node = node[m];
                    }

                    var key = map[map.length - 1];
                    var arrayStart = key.indexOf('[');
                    if (arrayStart == -1)
                        node[key] = value;
                    else {
                        var index = parseInt(key.substring(arrayStart + 1, arrayStart + (key.length - arrayStart - 1)));
                        var key = key.substring(0, arrayStart);

                        if (typeof node[key] === 'undefined')
                            node[key] = [];

                        node[key][index] = value;
                    }
                }

                return data;
            },
            loadTemplate: function (templateName) {
                // Parse our template ID selector and use it to search on page for template
                var $tmpl = $('#' + templateName.replace(/\//g, '\\/'));

                var d = $.Deferred();

                // If we couldn't find a template, and we can use remote templates, let's try that!
                if ($tmpl.length == 0 && settings.useRemoteTemplates) {
                    var tpath = settings.templatePath + templateName + '.html';

                    $.ajax({
                        dataType: 'html',
                        url: tpath,
                        cache: false,
                        success: function (src) {
                            $tmpl = $('<script type="text/x-handlebars-template" id="' + templateName + '"/>').html(src);
                            $('body').append($tmpl);

                            d.resolve($tmpl);
                        },
                        error: function () {
                            d.reject();
                        }
                    });
                }

                return d;
            }
        },
        setup: function () {
            $(document).on('click submit reset', 'form,a,button,input', function (e) {
                var $this = $(this);
                var handled = false;

                var action = $this.data('rter-action');
                if (action) {
                    // Figure out the path from the closest view wrapper for this element
                    var path = $this.closest('[data-role="rter-view"]').data('rter-path');
                    // Check to make sure this is a string and not undefined
                    if (typeof path === 'string') {
                        // Attempt to find a route object for this path
                        var r = rter.routes.get(path);
                        // Check to make sure we found a route (this should always be valid but just to be safe)
                        if (typeof r === 'object') {
                            // Let's check to see if our controller even has actions
                            if (typeof r._controller_instance.actions === 'object') {
                                // Let's check to see if we have a record for the specific action
                                if (typeof r._controller_instance.actions[action] === 'object') {
                                    // Load action data from controller
                                    var a = r._controller_instance.actions[action];
                                    if ($.inArray(e.type, a.on) !== -1) {
                                        a.action.apply(this, arguments);
                                        handled = true;
                                    }
                                }
                            }
                        }
                    }
                }

                if (e.type === 'click') {
                    // Handle our special rter links to actions
                    if ($this.is('[data-role="rter-link"]')) {
                        rter.routes.go($this.data('rter-path'), $this.data('rter-state'));
                        handled = true;
                    }
                }

                if (handled) e.preventDefault();
            });

            var actionId = 0;
            Handlebars.registerHelper('action', function (action, options) {
                if (typeof this.context._controller_instance === 'undefined')
                    return;

                if (typeof this.context._controller_instance.actions !== 'object') {
                    this.context._controller_instance.actions = {};
                }

                var controllerAction = this.context._controller_instance.actions[action];
                if (typeof controllerAction === 'function') {
                    var aid = ++actionId;
                    this.context._controller_instance.actions[aid] = {
                        on: options.hash.on.split(','),
                        action: controllerAction
                    };

                    return new Handlebars.SafeString('data-rter-action="' + aid + '"');
                }
            });

            Handlebars.registerHelper('link-to', function (path, options) {
                var attrs = '';
                var state = false;
                if (typeof options.hash !== 'undefined') {
                    for (var x in options.hash) {
                        var v = options.hash[x];

                        if (x == 'state') {
                            x = 'data-rter-state';
                            state = v;
                        }

                        attrs += ' ' + x + '=\'' + v + '\'';
                    }
                }

                return '<a href="#!/'+path + (state ? '?' + rter.utils.toParamString($.parseJSON(state)) : '') + '" data-role="rter-link" data-rter-path="' + path + '"' + attrs + '>' + options.fn(this) + '</a>';

            });

            return true;
        }(),
        settings: function () {
            return {
                set: function (options) {
                    settings = $.extend(settings, options);
                }
            };
        }(),
        url: function () {
            var expectedHash = null;
            var getCacheUrl = null;
            var getCacheData = null;

            if ("onhashchange" in window) {
                window.onhashchange = function (e) {
                    onHashChanged();
                };
            } else {
                alert('onhashchange not supported!');
            }

            return {
                set: function (path, state, replace) {
                    var newPath = '#!' + path;
                    if (typeof state === 'object') {
                        var paramString = rter.utils.toParamString(state);
                        if (paramString)
                            newPath += '?' + paramString;
                    }

                    // Make sure we know we 
                    expectedHash = newPath;

                    // We only want to update our location if our path changes, otherwise we screw with our history
                    if (window.location.hash !== newPath) {
                        if (window.history && window.history.pushState) {
                            if (replace) history.replaceState(null, '', newPath);
                            else history.pushState(null, '', newPath);
                        } else {
                            window.location = newPath;
                        }
                    }
                },
                get: function () {
                    var urlString = window.location.href || document.URL;

                    if (getCacheUrl === urlString)
                        return getCacheData;
                    else {
                        var urlReader = new RegExp('#!([^\\?]+)(\\?(.+))?');
                        var matches = urlReader.exec(urlString);

                        var url = {};
                        if (matches != null) {
                            url.path = matches[1];
                            if (matches[3]) {
                                url.state = rter.utils.parseParamString(matches[3]);
                            } else {
                                url.state = {};
                            }
                        }

                        getCacheUrl = urlString;
                        getCacheData = url;

                        return url;
                    }
                },
                state: {
                    get: function (key) {
                        var urlData = rter.url.get();
                        if (typeof key !== 'undefined') {
                            return urlData.state[key];
                        }

                        return urlData.state;
                    },
                    set: function (newState, clear) {
                        var urlData = rter.url.get();
                        if (clear == false)
                            newState = $.extend(newState, urlData.state);

                        rter.url.set(urlData.path, newState);
                    }
                }
            };

            function onHashChanged() {
                var newHash = window.location.hash;
                // Compare new hash to existing known hash
                if (expectedHash != newHash) {
                    // Our hash states changed, let's update this
                    var urlData = rter.url.get();
                    rter.routes.go(urlData.path, urlData.state);
                }
            }
        }(),
        routes: function () {
            var availableRoutes = {};
            var routeFilters = [];

            var defaultPath;

            var routing = false;
            var pendingRouteRequests = [];

            var currentRoute = null;
            var currentContext = {
                path: '',
                routes: []
            };

            return {
                set: function (newRoutes, shouldLoad) {
                    // Read our route data into our quick lookup table
                    availableRoutes = loadRouteData(newRoutes);
                    defaultPath = newRoutes.path;

                    if (typeof shouldLoad === 'undefined' || shouldLoad)
                        rter.routes.refresh();
                },
                refresh: function () {
                    var url = rter.url.get();

                    if (url.path) {
                        rter.routes.go(url.path, url.state);
                    } else {
                        // Go to our base route
                        rter.routes.go(defaultPath, {});
                    }
                },
                get: function (path) {
                    return findRoute(path);
                },
                go: function (path, state, options) {
                    if (routing) {
                        pendingRouteRequests.push(arguments);
                        return;
                    }

                    try {
                        options = $.extend({
                            saveState: false
                        }, options);

                        // Set our state to routing so we can't get overlapping requests
                        routing = true;

                        // If there was no explicit state set, and we are in the same path, we use existing state.
                        if (typeof state === 'undefined') {
                           var urlData = rter.url.get();
                           if (urlData.path && path && urlData.path.indexOf(path) !== -1) {
                               if (typeof urlData.state === 'undefined')
                                   state = {};
                               else
                                   state = urlData.state;
                           }
                        }

                        if (typeof state === 'string') {
                            try {
                                var parsedState = JSON.parse(state);
                                state = parsedState;
                            }
                            catch (ex) {
                                console.log('rter failed to parse state object from state string.');
                                state = null;
                            }
                        }

                        state = state || {};

                        var route = findRoute(path);

                        if (route === null)
                            error('Could not find path "' + path + '"');

                        if (!options.skipFilters) {
                            // Check all of our route filter functions and see if this action should be stopped
                            var deferredFilters = [];
                            for (var i = 0; i < routeFilters.length; i++) {
                                var filterResponse = routeFilters[i](route, state);

                                if (filterResponse === false) {
                                    routing = false;
                                    return false;
                                } else if (typeof filterResponse === 'object' && typeof filterResponse.done === 'function' && typeof filterResponse.fail === 'function') {
                                    deferredFilters.push(filterResponse);
                                }
                            }

                            // Wait for all of the filters that returned with a deferred 
                            if (deferredFilters.length) {
                                $.when.apply($, deferredFilters).done(function () {
                                    rter.routes.go(path, state, $.extend({ skipFilters: true }, options));
                                });

                                return;
                            }
                        }

                        var pendingChain = [];

                        // Navigate our route hierarchy starting at our new controller until we find the root
                        var node = route;
                        while (node != null) {
                            pendingChain.push(node);
                            node = node.parent;
                        }

                        // Reverse these to be proper order
                        pendingChain.reverse();

                        var changeCount = Math.max(currentContext.routes.length, pendingChain.length);
                        var changes = [];

                        // Flag tells us that the chain is dirty and we need to replace everything after that.
                        var chainDirty = false;

                        // Compare our pending chain to our existing chain to identify where changes need to happen
                        for (var i = 0; i < changeCount; i++) {
                            if (i >= currentContext.routes.length || (chainDirty && i < pendingChain.length)) {
                                changes[i] = 'add';
                            } else if (i >= pendingChain.length) {
                                changes[i] = 'remove';
                                chainDirty = true;
                            } else {
                                var oldRoute = currentContext.routes[i];
                                var newRoute = pendingChain[i];

                                if (oldRoute.id != newRoute.id) {
                                    changes[i] = 'replace';
                                } else if (newRoute._replaced && path != currentContext.path) {
                                    changes[i] = 'replace';
                                    chainDirty = true;
                                } else {
                                    changes[i] = 'none';
                                }
                            }

                            if (i < pendingChain.length)
                                delete pendingChain[i]._replaced;
                        }


                        var d = new Date();
                        var context = {
                            path: pendingChain[pendingChain.length - 1].path,
                            routes: pendingChain,
                            state: state,
                            previousContext: currentContext,
                            previousState: rter.url.state.get()
                        };

                        rter.url.set(route.path, state);

                        // Destroy any old controllers that have been removed
                        for (var i = changeCount - 1; i >= 0; i--) {
                            if (changes[i] == 'remove' || changes[i] == 'replace') {
                                var r = currentContext.routes[i];

                                var $view = $('#rter_view_' + r.id);

                                $view.triggerHandler('view-will-unload');

                                if (r._controller_instance.viewWillUnload)
                                    r._controller_instance.viewWillUnload($view, context);

                                if (options.saveState) {

                                    // Notify our controller that this view is going to be detached
                                    if (r._controller_instance.viewWillDetatch)
                                        r._controller_instance.viewWillDetatch($view, context);

                                    // Setup our state cache for this route
                                    if (typeof r._states === 'undefined')
                                        r._states = {};

                                    // Save a new state for this path
                                    r._states[currentContext.path] = {
                                        // Save our old view state information
                                        _save_state: {
                                            scrollTop: $(window).scrollTop()
                                        },
                                        _saved_view_instance: $view,
                                        _controller_instance: r._controller_instance
                                    };

                                    // Detatch our DOM elements and store them on the route as a temporary state
                                    $view.detach().triggerHandler('view-detached');

                                    // Notify the controller that the view has been detached and is suspended
                                    if (r._controller_instance.viewDetached)
                                        r._controller_instance.viewDetached($view, context);

                                } else {
                                    // Notify our controller that this view is going to be removed
                                    if (r._controller_instance.viewWillRemove)
                                        r._controller_instance.viewWillRemove($view, context);

                                    // Remove the UI for this controllers view
                                    $view.remove().triggerHandler('view-removed');

                                    // Notify the controller that the dom elements have been removed
                                    if (r._controller_instance.viewRemoved)
                                        r._controller_instance.viewRemoved($view, context);
                                }

                                $view.triggerHandler('view-unloaded');

                                if (r._controller_instance.viewUnloaded)
                                    r._controller_instance.viewUnloaded($view, context);

                                // Clear our controller instance since we are removed from view
                                delete r._controller_instance;
                            }
                        }

                        // Create the views for any new controllers
                        for (var i = 0; i < changeCount; i++) {
                            var r = pendingChain[i];

                            if (changes[i] == 'add' || changes[i] == 'replace') {
                                var s = null;

                                if (typeof r._states !== 'undefined') {
                                    for (var x in r._states) {
                                        if (x.indexOf(context.path) == 0) {
                                            s = r._states[x];
                                            delete r._states[x];
                                            break;
                                        }
                                    }
                                }

                                // Create a new controller instance if the other one hasn't been saved
                                if (s) {
                                    r._controller_instance = s._controller_instance;
                                    delete s._controller_instance;
                                } else {
                                    r._controller_instance = (typeof r.controller === 'function') ? r.controller(context) : {};
                                }

                                context._controller_instance = r._controller_instance;

                                var $outlet = null;

                                var replacedView = false;
                                var outletChild = r;
                                var outletTarget = r.parent;
                                while ($outlet == null) {
                                    if (outletTarget == null) {
                                        $outlet = $('[data-role="rter-viewport"]');
                                        replacedView = false;
                                    } else {
                                        var $found = $('#rter_view_' + outletTarget.id + ' [data-role="rter-outlet"][data-rter-view="' + outletTarget.id + '"]');
                                        if ($found.length > 0) {
                                            $outlet = $found;
                                            break;
                                        }

                                        outletChild = outletTarget;
                                        outletTarget = outletTarget.parent;
                                        replacedView = true;
                                    }
                                }

                                if (replacedView)
                                    outletChild._replaced = true;

                                if ($outlet.length == 0) error('Cannot find outlet for route "' + r.path + '".');
                                if ($outlet.length > 1) error('Multiple outlets found for route "' + r.path + '".')

                                if (typeof r._controller_instance.viewWillLoad === 'function')
                                    r._controller_instance.viewWillLoad(context);

                                if (r.hasView !== false) {
                                    if (typeof r.view === 'function') {
                                        error('Custom view functions are not implemented!');
                                    } else {
                                        var vid = r.path;

                                        // If the route has its own view string, let's use that!
                                        if (typeof r.view === 'string')
                                            vid = r.view;

                                        // Make sure we use index instead of / for root
                                        if (vid == '/') vid = 'index';

                                        // Remove leading/trailing slashes from the route name for template lookup
                                        var lastChar = vid.length - 1;
                                        var templateName = '';
                                        for (var x = 0; x <= lastChar; x++) {
                                            var c = vid[x];
                                            if (c == '/' && (x == 0 || x == lastChar)) continue;
                                            templateName += c;
                                        }

                                        // Setup a temporary element to hold our view object
                                        var $view = null;

                                        if (s) {
                                            $view = s._saved_view_instance;
                                            delete s._saved_view_instance;

                                            if (typeof r._controller_instance.viewWillResume === 'function')
                                                r._controller_instance.viewWillResume($view, context);

                                            if ($view) {
                                                $view.triggerHandler('view-will-resume');
                                                $outlet.html($view);
                                                $view.triggerHandler('view-resumed');
                                            }

                                            if (typeof r._controller_instance.viewResumed === 'function')
                                                r._controller_instance.viewResumed($view, context);

                                            (function (r, states) {
                                                setTimeout(function () {
                                                    $(window).scrollTop(states.scrollTop);
                                                }, 0);
                                            })(r, s._save_state);


                                            delete s._save_state;
                                        } else {
                                            // Parse our template ID selector and use it to search on page for template
                                            var $tmpl = $('#' + templateName.replace(/\//g, '\\/'));

                                            // If we couldn't find a template, and we can use remote templates, let's try that!
                                            if ($tmpl.length == 0 && settings.useRemoteTemplates) {
                                                var tpath = settings.templatePath + templateName + '.html';

                                                $.ajax({
                                                    dataType: 'html',
                                                    url: tpath,
                                                    async: false,
                                                    cache: false,
                                                    success: function (src) {
                                                        $tmpl = $('<script type="text/x-handlebars-template" id="' + templateName + '"/>').html(src);
                                                        $('body').append($tmpl);
                                                    }
                                                });
                                            }

                                            if ($tmpl.length != 0) {
                                                $view =
                                                    $('<div id="rter_view_' + r.id + '" data-role="rter-view" />')
                                                    .append(Handlebars.compile($tmpl.html())({
                                                        _date: {
                                                            year: d.getFullYear(),
                                                            month: d.getMonth(),
                                                            day: d.getDay()
                                                        },
                                                        context: context
                                                    }))
                                                    .attr({
                                                        'data-rter-path': r.path
                                                    });

                                                $view.find('[data-role="rter-outlet"]').attr('data-rter-view', r.id);
                                            }

                                            if (typeof r._controller_instance.viewWillCreate === 'function')
                                                r._controller_instance.viewWillCreate($view, context);

                                            if ($view) {
                                                $view.triggerHandler('view-will-create');
                                                $outlet.html($view);
                                                $view.triggerHandler('view-created');
                                            }

                                            if (typeof r._controller_instance.viewCreated === 'function')
                                                r._controller_instance.viewCreated($view, context);
                                        }

                                        if ($view) $view.triggerHandler('view-attached');

                                        if (typeof r._controller_instance.viewAttached === 'function')
                                            r._controller_instance.viewAttached($view, context);
                                    }
                                }

                                context.$outlet = $outlet;

                                if (typeof r._controller_instance.viewLoaded === 'function')
                                    r._controller_instance.viewLoaded(context);

                            } else if (changes[i] === 'none') {
                                if (typeof r._controller_instance.viewChainChanged === 'function')
                                    r._controller_instance.viewChainChanged(context);
                            }
                        }

                        for (var i = 0; i < pendingChain.length; i++) {
                            var c = pendingChain[i]._controller_instance;
                            if (typeof c.viewStateChanged === 'function') {
                                c.viewStateChanged(context);
                            }
                        }

                        currentContext = context;
                        currentRoute = route;
                    } finally {
                        routing = false;
                    }

                    if (pendingRouteRequests.length > 0) {
                        rter.routes.go.apply(this, pendingRouteRequests.pop());
                    }
                },
                filters: {
                    add: function (filter) {
                        if (typeof filter === 'function')
                            routeFilters.push(filter);
                    }
                }
            };

            function findRoute(path) {
                // Setup an array of paths to try
                var routeSearchArray = [];

                // If we can find the exact route, why look further?
                routeSearchArray.push(path);

                // Next, let's check the relative path from our current route (if we have one)
                if (currentRoute != null)
                    routeSearchArray.push(currentRoute.path + '/' + path);

                // If we are looking for the index we know that path!
                if (path == 'index')
                    routeSearchArray.push('/');

                // Let's assume they left off the index path for the route and see if that is a hit
                routeSearchArray.push('/' + path);

                var foundRoute = false;
                for (var i = 0; i < routeSearchArray.length; i++) {
                    var r = routeSearchArray[i];
                    if (typeof availableRoutes[r] != 'undefined') {
                        return availableRoutes[r];
                    }
                }

                return null;
            }

            function loadRouteData(route, parent, list) {
                if (typeof list === 'undefined') {
                    list = {
                        '_rter_count': 0
                    };
                }

                // If this is a root node then we do some special setup
                if (!parent) {
                    // Set the base path to be URL/ so other sub routes are /subroute/anothersubroute
                    if (typeof route.path === 'undefined')
                        route.path = '/';
                }

                // Setup additional route attributes
                route.id = list['_rter_count'] + 1;
                route.parent = (typeof parent !== 'undefined' ? parent : null);
                route.path = (typeof route.path !== 'undefined') ? route.path : ((route.parent == null) ? route.name : ((route.parent.path == '/') ? '/' + route.name : route.parent.path + '/' + route.name));

                if (typeof list[route.path] != 'undefined')
                    error('Cannot define multiple routes with the same path.')

                list[route.path] = route;
                list['_rter_count']++;

                // Load all of our children routes if we have them
                if (typeof route.routes !== 'undefined') {
                    for (var i = 0; i < route.routes.length; i++) {
                        loadRouteData(route.routes[i], route, list);
                    }
                }

                return list;
            }
        }()
    };

    function error(message) {
        throw new Error('RterError: ' + message);
    }
}();