(function () {
    /**
     * The available RideStyler environments
     * @enum {string}
     * @readonly
     */
    var ENVIRONMENTS = {
        /** For development and debugging */
        Debug: "debug",

        /** Connect to the alpha RideStyler environment */
        Alpha: "alpha",

        /** Connect to the beta RideStyler environment */
        Beta: "beta",

        /** Connect to the live RideStyler environment */
        Live: "live"
    };

    // If developing set the environment to Debug and update the api connection 
    // at the end of the file if needed

    var environment = window.rideStylerEnvironment || ENVIRONMENTS.Debug;
    var debug = environment !== ENVIRONMENTS.Live && environment !== ENVIRONMENTS.Beta;
    var stripeToken;

    /**
     * Add a script to the DOM to load it
     * @param {string} src The source URL of the script to add,
     * @param {object} params Params to include in the url parameters
     */
    function addScript (src, params, onload) {
        if (params) {
            if (src.indexOf('?') === -1) src += '?';
            else src += '&';

            for (var key in params) {
                if (params.hasOwnProperty(key)) {
                    var value = params[key];
                    var lastUrlChar = src[src.length - 1];

                    if (lastUrlChar !== '&' && lastUrlChar !== '?') src += '&'
                    
                    src += key + '=' + value;
                }
            }
        }

        var script = document.createElement("script");
            script.type = "text/javascript";
            script.src = src;
            script.async = true;
        
        if (typeof onload === "function")
            script.onreadystatechange = script.onload = function () {
                var state = script.readyState;

                if (!onload.done && (!state || state === "loaded" || state === "complete")) {
                    onload.done = true;
                    onload();
                }
            };

        document.body.appendChild(script);
    }

    function finishLoading() {
        ridestyler.user.ready.done(function () {
            rter.routes.refresh();
        });
    }

    var rsAPIParams = {
        NoCache: true,
        UserMode: true,
        LoginKey: 'A0FC1060-6011-4FC3-8F5F-C31E84ECB150'
    };

    if (environment === ENVIRONMENTS.Live) {
        stripeToken = "pk_live_sMht90uCW6AXG7GrhZ4YtLCm";
        addScript("https://api.ridestyler.net/js", rsAPIParams, finishLoading);
    } else {
        stripeToken = "pk_test_2GMjQwgEoQRiUjqNrHJ6ePnE";

        if (environment === ENVIRONMENTS.Alpha)
            addScript("https://api-alpha.ridestyler.net/js", rsAPIParams, finishLoading);
        else if (environment === ENVIRONMENTS.Beta)
            addScript("https://api-beta.ridestyler.net/js", rsAPIParams, finishLoading);
        else {
            addScript('https://api-alpha.ridestyler.net/js', rsAPIParams, finishLoading);
            // If you need to use a different API URL add it here
            // addScript("http://localhost:6888/js?NoCache=true", finishLoading);
        }
    }

    window.ENVIRONMENTS = ENVIRONMENTS;
    window.environment = environment;
    window.debug = debug;
    window.stripeToken = stripeToken;
})();