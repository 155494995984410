(function ($) {
    var serializeableSelector = ':input,.rs-dropdown,.ms-ctn,.rs-form-search,.selection-list';

    $.fn.notChildren = function () {
        var elements = this;
        return this.map(function () {
            var isChild = false;
            var el = this;

            elements.each(function () {
                if ($.contains(this, el)) {
                    isChild = true;
                    return false;
                }
            });

            return isChild ? undefined : el;
        });
    }

    /**
     * Returns a function that when called will only call func
     * if the timeout has elapsed without a call to the return function.
     * @param {int} timeout The time in milliseconds to wait before calling func.
     * @param {function} func The function to call.
     * @param {object} context The context to use. If undefined the context will be the same as the calling context.
     * @returns {function} 
     */
    $.debounce = function(timeout, func, context) {
        var timer;

        // Called with just a function
        if (typeof timeout === "function") {
            context = func;
            func = timeout;
            timeout = 250;
        }

        return function() {
            var args = arguments;
            var thisContext = this;
            if (timer) clearTimeout(timer);

            timer = setTimeout(function() {
                func.apply(context || thisContext, args);
            }, timeout);
        };
    };

    var dataTypeTransforms = {
        formToObject: {
            'string-list-to-json': function (/** @type {string} */ value) {
                var values = value.split(',');
                
                for (var i = 0; i < values.length; i++) 
                    values[i] = values[i].trim();

                return JSON.stringify(value.split(','));
            }
        },
        objectToForm: {
            'string-list-to-json': function (value) {
                try {
                    return JSON.parse(value).join(', ');
                } catch(e) {
                    return '';
                }
            }
        }
    }

    $.fn.syncFormToObject = function (outObject) {
        this.find(serializeableSelector).add(this.filter(serializeableSelector)).notChildren().each(function () {
            var $this = $(this);

            if ($this.is(':input')) {
                var name = this.name,
                    type = this.type,
                    dataType = $this.data('type'),
                    value = this.value;

                // We don't want to parse input children of MagicSuggest elements
                if ($this.closest('.ms-ctn').length) return;

                if (type === 'checkbox') {
                    if (this.checked) outObject[name] = (!dataType || dataType === 'boolean') ? true : value;
                    else delete outObject[name];
                } else if (type === 'file') {
                    if (this.files.length) outObject[name] = this.files[0];
                    else delete outObject[name];
                } else if (dataType === 'datetime') {
                    if (value.length) outObject[name] = moment(value).format();
                    else delete outObject[name];
                } else {
                    // Text input handling
                    if (value.length) {
                        if (dataType in dataTypeTransforms.formToObject) {
                            outObject[name] = dataTypeTransforms.formToObject[dataType](value);
                        } else {
                            outObject[name] = value;
                        }
                    }
                    else delete outObject[name];
                }
            } else if ($this.is('.rs-dropdown')) {
                // Get data from rs-dropdown
                var dropdown = $this.data('rs-dropdown'),
                    value = dropdown.selectedOption ? dropdown.selectedOption.value : "",
                    dataType = $this.data('type'),
                    name = $this.data('name');

                if (dropdown.settings.multiple) {
                    if (dropdown.selectedOptions.length > 0) {
                        if (dataType === 'array') {
                            value = $.map(dropdown.selectedOptions, function(o) { return o.value; });
                        } else {
                            value = $.map(dropdown.selectedOptions, function(o) { return o.value; }).join(',');
                        }
                    } else value = '';

                    if (dropdown.selectedOptions.length === 1 && dropdown.selectedOptions[0].value === false) value = false;
                }

                if (value) outObject[name] = value;
                else delete outObject[name];
            } else if ($this.is('.ms-ctn')) {
                // Get data from magic suggests
                var ms = $this.data('magicSuggest'),
                    config = $this.data('config'),
                    value = ms.getValue(),
                    name = config.name;

                if (!config.multiple) {
                    if (value.length) outObject[name] = value[0];
                    else delete outObject[name];
                } else {
                    if (value.length) outObject[name] = value;
                    else delete outObject[name];
                }
            } else if ($this.is('.rs-form-search')) {
                // Get data from RS Search Inputs
                var valueInput = $this.find('input[type=hidden]').get(0),
                    name = valueInput.name,
                    value = valueInput.value;

                if (value) outObject[name] = value;
                else delete outObject[name];
            } else if ($this.is('.selection-list')) {
                // Get data from selection lists
                var selectionList = $this.data('selectionList'),
                    value = selectionList.value(),
                    name = $this.data('name');

                if (value) outObject[name] = value;
                else delete outObject[name];
            }
        });

        return this;
    };

    $.fn.syncObjectToForm = function (inObject, clearMissing) {
        clearMissing = typeof clearMissing === 'undefined' ? true : !!clearMissing;

        this.find(serializeableSelector).add(this.filter(serializeableSelector)).notChildren().each(function () {
            var $this = $(this);

            if ($this.is(':input')) {
                var name = this.name,
                    type = this.type,
                    dataType = $this.data('type'),
                    value = name in inObject ? inObject[name] : undefined;

                if (typeof value === 'undefined' && !clearMissing) return;

                if (type === 'checkbox') {
                    this.checked = !!value;
                    $.fn.iCheck && $(this).iCheck('update');
                } else if (dataType === 'datetime') {
                    this.value = value ? moment(value).format("MMM DD YYYY hh:mm A") : '';
                } else {
                    if (!value) value = "";
                    else if (dataType in dataTypeTransforms.objectToForm) {
                        value = dataTypeTransforms.objectToForm[dataType](value);
                    }

                    this.value = value;
                }
            } else if ($this.is('.rs-dropdown')) {
                var name = $this.data('name'),
                    value = name in inObject ? inObject[name] : undefined,
                    dropdown = $this.data('rs-dropdown');

                if (dropdown) {
                    if (dropdown.settings.multiple) {
                        if (!$.trim(value)) {
                            dropdown.setSelectedOption(false);
                        } else {
                            $.each(value = value.toString().split(','), function (i, v) {
                                dropdown.setSelectedOption(v);
                            });

                            dropdown.settings.value = value;
                        }
                    } else {
                        dropdown.setSelectedOption(value);
                    }
                }
            } else if ($this.is('.ms-ctn')) {
                var ms = $this.data('magicSuggest'),
                    config = $this.data('config'),
                    name = config.name,
                    value = name in inObject ? inObject[name] : undefined;

                ms.setValue($.makeArray(value));
            } else if ($this.is('.rs-form-search')) {
                var search = $this.data('rsSearch'),
                    name = search.name;

                if (name in inObject) search.setSelectedValue(inObject[name]);
                else search.clearValue();
            } else if ($this.is('.selection-list')) {
                var selectionList = $this.data('selectionList'),
                    name = $this.data('name'),
                    value = name in inObject ? inObject[name] : undefined;

                if (selectionList) {
                    selectionList.setSelections(value);
                }
            }
        });

        return this;
    };

    /**
     * jQuery.fn.sortElements
     * --------------
     * @author James Padolsey (http://james.padolsey.com)
     * @version 0.11
     * @updated 18-MAR-2010
     * --------------
     * @param Function comparator:
     *   Exactly the same behaviour as [1,2,3].sort(comparator)
     *   
     * @param Function getSortable
     *   A function that should return the element that is
     *   to be sorted. The comparator will run on the
     *   current collection, but you may want the actual
     *   resulting sort to occur on a parent or another
     *   associated element.
     *   
     *   E.g. $('td').sortElements(comparator, function(){
     *      return this.parentNode; 
     *   })
     *   
     *   The <td>'s parent (<tr>) will be sorted instead
     *   of the <td> itself.
     */
    jQuery.fn.sortElements = (function () {

        var sort = [].sort;

        return function (comparator, getSortable) {

            getSortable = getSortable || function () { return this; };

            var placements = this.map(function () {

                var sortElement = getSortable.call(this),
                    parentNode = sortElement.parentNode,

                    // Since the element itself will change position, we have
                    // to have some way of storing it's original position in
                    // the DOM. The easiest way is to have a 'flag' node:
                    nextSibling = parentNode.insertBefore(
                        document.createTextNode(''),
                        sortElement.nextSibling
                    );

                return function () {

                    if (parentNode === this) {
                        throw new Error(
                            "You can't sort elements if any one is a descendant of another."
                        );
                    }

                    // Insert before flag:
                    parentNode.insertBefore(this, nextSibling);
                    // Remove flag:
                    parentNode.removeChild(nextSibling);

                };

            });

            return sort.call(this, comparator).each(function (i) {
                placements[i].call(getSortable.call(this));
            });

        };

    })();

    (function () {
        var filthypillow = jQuery.fn.filthypillow;

        jQuery.fn.filthypillow = function (method) {
            filthypillow.apply(this, arguments);

            if (typeof method === 'string') {
                this.trigger('fp:rs:' + method);
            }

            return this;
        };
    })();

    (function () {
        var rClass = /[\t\r\n\f ]/g;

        jQuery.fn.hasClasses = function (classList) {
            classList = classList.split(rClass);

            for (var i = 0; i < classList.length; i++)
                if (!this.hasClass(classList[i]))
                    return false;

            return true;
        };

        jQuery.fn.withClasses = function (classList) {
            return this.pushStack(jQuery.grep(this, function (element) {
                return $(element).hasClasses(classList);
            }));
        };

        jQuery.fn.withoutClasses = function (classList) {
            return this.pushStack(jQuery.grep(this, function (element) {
                return $(element).hasClasses(classList);
            }, true));
        };
    })();
})(jQuery);