(function ($) {
    $.fn.smartSerialize = function (p) {
        if (typeof p === 'undefined') {
            var response = { values: {}, errors: [] };

            this.find('input,textarea,select,.noUi-target').each(function () {
                var $input = $(this);
                var t = $input.attr('type');
                var name = $input.attr('name') || $input.attr('id');

                if (name) {
                    var val = null;

                    if (t === 'checkbox' || t === 'radio')
                        val = this.checked;
                    else
                        val = $input.val();

                    response.values[name] = val;

                    var validationString = $input.data('validate');
                    if (validationString) {
                        var res = function (val, validationString) { try { return eval(validationString); } catch (ex) { return false; } }(val, validationString);
                        if (res === false && $input.is(':visible')) {
                            var textName = name;
                            if (textName[0] === '#')
                                textName = textName.substring(1);

                            var fieldName = $('label[for="' + textName + '"]').text();
                            if (!fieldName) fieldName = name;

                            var errorMsg = $input.data('validate-message') || (fieldName + ' is required to submit this form.');
                            response.errors.push(errorMsg);
                            $input.closest('.form-group').addClass('has-error');

                        } else {
                            $input.closest('.form-group').removeClass('has-error');
                        }
                    }


                }
            });

            return response;
        } else {
            this.find('input,textarea,select').each(function () {
                var $input = $(this);
                var t = $input.attr('type');
                var name = $input.attr('name') || $input.attr('id');
                var pvalue = p[name];

                if (name) {
                    var val = null;

                    if (t === 'checkbox' || t === 'radio')
                        this.checked = pvalue;
                    else
                        $input.val(pvalue);
                }
            });
        }
    };
})(jQuery);