// Extensions to core Javascript functionality
// ReSharper disable once NativeTypePrototypeExtending
(function () {
    Array.prototype.unique = function () {
        var o = {}, i, l = this.length, r = [];
        for (i = 0; i < l; i += 1) o[this[i]] = this[i];
        for (i in o) r.push(o[i]);
        return r;
    };

    if (!Object.keys) {
        Object.keys = function (o) {
            /// <param name="o" type="object"></param>
            /// <returns type="any[]"></returns>
            if (o !== Object(o))
                throw new TypeError('Object.keys called on a non-object');
            var k = [], p;
            for (p in o) if (Object.prototype.hasOwnProperty.call(o, p)) k.push(p);
            return k;
        }
    }

    if (!Array.prototype.lastIndexOf) {
        Array.prototype.lastIndexOf = function (searchElement /*, fromIndex*/) {
            'use strict';

            if (this === void 0 || this === null) {
                throw new TypeError();
            }

            var n, k,
              t = Object(this),
              len = t.length >>> 0;
            if (len === 0) {
                return -1;
            }

            n = len - 1;
            if (arguments.length > 1) {
                n = Number(arguments[1]);
                if (n != n) {
                    n = 0;
                }
                else if (n != 0 && n != (1 / 0) && n != -(1 / 0)) {
                    n = (n > 0 || -1) * Math.floor(Math.abs(n));
                }
            }

            for (k = n >= 0 ? Math.min(n, len - 1) : len - Math.abs(n) ; k >= 0; k--) {
                if (k in t && t[k] === searchElement) {
                    return k;
                }
            }
            return -1;
        };
    }

    $.inArrayReverse = function (elem, arr, i) {
        return arr == null ? -1 : Array.prototype.lastIndexOf.call(arr, elem, i);
    };
})();